import React, { useEffect } from 'react';
import ProjectList from './ProjectList'; // ProjectList コンポーネントをインポート
import { Helmet } from 'react-helmet'; // react-helmetをインポート


const PublicProjectsPage = () => {

  return (
    <div>
      <Helmet>
        <title>ホーム | LASUPLOAD</title>
        <meta property="og:type" content="website"></meta>
        <meta name="title" content="ホーム | LASUPLOAD" />
        <meta name="description" content="Share Your Point Cloud Online." />
        <meta property="og:title" content="ホーム | LASUPLOAD" />
        <meta property="og:description" content="Share Your Point Cloud Online." />
        <meta property="og:image" content="https://lasupload.com/thumbnail1200.png" />
        <meta property="og:url" content="https://lasupload.com" />

        <meta name="twitter:site" content="@kiraunix" />
        <meta name="twitter:creator" content="@kiraunix" />
        <meta name="twitter:url" content="https://lasupload.com" />
        <meta name="twitter:title" content="ホーム | LASUPLOAD" />
        <meta name="twitter:description" content="Share Your Point Cloud Online." />
        <meta name="twitter:image" content="https://lasupload.com/thumbnail1200.png" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {/* ProjectList コンポーネントを利用して公開プロジェクトを表示 */}
      <ProjectList showPublicOnly={true} />
    </div>
  );
};

export default PublicProjectsPage;