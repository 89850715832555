import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Input, Button, useToast, VStack, Link, Center, Text, Heading } from '@chakra-ui/react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

const LoginForm = () => {

  useEffect(() => {
    document.title = "ログイン | LASUPLOAD";
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate(); // ログイン成功後のリダイレクト用

  const handleLogin = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // メール認証状態のチェック
        const user = userCredential.user;
        if (!user.emailVerified) {
          // メール認証が完了していない場合
          toast({
            title: 'メール認証が必要です',
            description: 'アカウントのメールアドレスに送信されたリンクをクリックして、認証を完了してください。',
            status: 'warning',
            duration: 9000,
            isClosable: true,
          });
          // 認証が完了していないため、ログイン処理を中断
          return;
        }

        // ログイン成功時の処理
        toast({
          title: 'ログイン成功',
          description: 'ログインに成功しました。',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/projects'); // ログイン成功後にホームページへリダイレクト
      })
      .catch((error) => {
        // エラー時の処理
        let errorMessage = error.message;
        switch (error.code) {
          case 'auth/user-not-found':
            errorMessage = 'アカウントが存在しません。';
            break;
          case 'auth/wrong-password':
            errorMessage = 'パスワードが間違っています。';
            break;
          case 'auth/invalid-credential':
            errorMessage = 'アカウントが存在しないか、パスワードが間違っています。';
            break;
          default:
            // デフォルトのエラーメッセージを使用
        }
        toast({
          title: 'ログイン失敗',
          description: errorMessage,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <Center minHeight="calc(100vh - 60px - 60px)">
      <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
        <form onSubmit={handleLogin}>
          
          <VStack spacing={4}>
          <Heading as="h2" size="md" fontWeight="bold" textAlign="center">ログイン</Heading>
            <FormControl isRequired>
              <FormLabel>メールアドレス</FormLabel>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>パスワード</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            <Button type="submit" colorScheme="blue" width="full" mt={4}>
              ログイン
            </Button>
          </VStack>
        </form>
        <Text mt={4} textAlign="center">
          パスワードを忘れた方は
          <Link as={RouterLink} to="/reset-password" color="teal.500" ml={1}>
            こちら
          </Link>
        </Text>
      </Box>
    </Center>
  );
};

export default LoginForm;
