import React, { useState, useEffect } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Firebase Auth オブジェクトのインポート
import { Box, FormControl, FormLabel, Input, Button, useToast, VStack } from '@chakra-ui/react';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const toast = useToast();

  useEffect(() => {
    document.title = "パスワードリセット | LASUPLOAD";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        title: 'パスワードリセットメール送信完了',
        description: "指定したメールアドレスにパスワードリセットの指示を送信しました。",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'エラー',
        description: "メール送信に失敗しました。メールアドレスを確認してください。",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={8} maxWidth="500px" mx="auto">
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor='email'>メールアドレス</FormLabel>
            <Input
              id='email'
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='メールアドレスを入力'
            />
          </FormControl>
          <Button
            type='submit'
            colorScheme='blue'
            width='full'
          >
            パスワードリセットメールを送信
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ResetPassword;
