import React, { useEffect, useState } from 'react';
import {
  Flex,
  Image,
  Text,
  Box,
  Button,
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  MenuItem,
  useColorModeValue,
  IconButton,
} from '@chakra-ui/react';
import { FaUpload } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // AuthContextからuseAuthをインポート
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Firestoreの設定をインポート

const Header = () => {
  const { currentUser, logout } = useAuth(); // currentUserとlogout関数をuseAuthから取得
  const navigate = useNavigate();
  const [customId, setCustomId] = useState('');

  useEffect(() => {
    if (currentUser) {
      const fetchCustomId = async () => {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setCustomId(userSnap.data().customId); // customIdをstateに保存
        }
      };
      fetchCustomId();
    }
  }, [currentUser]);

  const handleLogout = async () => {
    await logout(); // AuthProviderで提供されるlogout関数を使用
    navigate('/login'); // ログアウト後にログインページにリダイレクト
  };

  return (
    <Flex
      bg={useColorModeValue('gray.100', 'gray.900')}
      color={useColorModeValue('black', 'white')}
      px={4}
      py={2}
      justifyContent="space-between"
      alignItems="center"
      width="full"
    >
      <Flex alignItems="center" cursor="pointer" onClick={() => navigate('/')}>
        <Image
          src="/lasuploadlogo.png"
          alt="LASUPLOAD Logo"
          htmlWidth="300px"
        />
        <Box
          ml={1}
          px={1}
          py={0}
          bg="blue.500"
          borderRadius="md"
          display="inline-block"
        >
          <Text color="white" fontSize="sm">beta</Text>
        </Box>
      </Flex>
      <Flex alignItems="center">
        {currentUser ? (
          <>
            <Button mr={4} onClick={() => navigate('/projects')} variant="ghost">
              プロジェクト一覧
            </Button>
            <IconButton
              icon={<FaUpload />}
              mr={4}
              onClick={() => navigate('/upload')}
              aria-label="アップロード"
              variant="ghost"
            />
            <Menu>
              <MenuButton as={Button} rightIcon={<Avatar size="xs" name={currentUser.displayName || currentUser.email} />}>
                {currentUser.displayName || 'No Name'}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => navigate('/account')}>アカウント情報</MenuItem>
                <MenuItem onClick={() => navigate(`/user/${customId}`)}>公開プロフィールページ</MenuItem>
                <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
              </MenuList>
            </Menu>
          </>
        ) : (
          <>
            <Button mr={4} onClick={() => navigate('/login')} colorScheme="teal">
              ログイン
            </Button>
            <Button onClick={() => navigate('/signup')} colorScheme="orange">
              新規登録
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default Header;
