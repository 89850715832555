// src/context/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { Spinner, Box, Center } from "@chakra-ui/react";  // SpinnerとBox, Centerをインポート

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);  // 初期値をnullに設定
  const [loading, setLoading] = useState(true);  // 認証の初期読み込み状態を追跡
  const auth = getAuth();  // Firebase Authの初期化

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setLoading(false);  // ユーザーの認証状態が確認されたらローディングを終了
    });

    return () => unsubscribe();  // コンポーネントのアンマウント時に購読を解除
  }, [auth]);

  // ユーザーのログアウト処理
  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
    } catch (error) {
      console.error('Failed to logout:', error);
    }
  };

  const value = {
    currentUser,
    loading,  // ローディング状態もコンテキストに含める
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : (
        <Center height="100vh">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      )}
    </AuthContext.Provider>
  );
}

