import React, { useEffect, useState, useRef } from 'react';
import { collection, query, where, getDocs, orderBy, limit, startAfter, doc, getDoc} from 'firebase/firestore';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { Box, Text, SimpleGrid, Input, Icon, Flex, Button, Skeleton } from '@chakra-ui/react';
import { AiOutlineUser, AiTwotoneCalendar, AiOutlineSearch } from 'react-icons/ai';

const ProjectList = ({ userId, showPublicOnly = true, showDisplayName = true }) => {
  const [searchText, setSearchText] = useState('');
  const [projects, setProjects] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const observer = useRef();

  const fetchProjects = async (lastDoc) => {
    setLoading(true);
    const constraints = [orderBy('createdAt', 'desc'), limit(20), where('status', '==', 'completed')];
  
    if (userId) {
      constraints.push(where('userId', '==', userId));
    }
  
    if (showPublicOnly) {
      constraints.push(where('isPublic', '==', true));
    }
  
    if (searchText) {
      constraints.push(where('projectName', '>=', searchText));
      constraints.push(where('projectName', '<=', searchText + '\uf8ff'));
    }
  
    const q = lastDoc ? query(collection(db, 'projects'), ...constraints, startAfter(lastDoc)) : query(collection(db, 'projects'), ...constraints);
    const querySnapshot = await getDocs(q);
  
    // Fetch user details in parallel
    const projectsWithUserDetails = querySnapshot.docs.map(async docSnapshot => {
      const project = docSnapshot.data();
      project.id = docSnapshot.id;
      project.createdAt = project.createdAt?.toDate().toLocaleString('ja-JP');
  
      const userRef = doc(db, 'users', project.userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        project.customId = userDoc.data().customId;  // Ensure customId is fetched before setting state
        project.displayName = userDoc.data().displayName;  // Ensure customId is fetched before setting state
      }
  
      return project;
    });
  
    const newProjects = await Promise.all(projectsWithUserDetails);
  
    setProjects(prev => lastVisible ? [...prev, ...newProjects] : [...newProjects]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);
  };
  
  useEffect(() => {
    fetchProjects(null);  // Initial fetch with empty searchText
  }, [userId, showPublicOnly]);  // Fetch only on userId or showPublicOnly change
  

  const handleProjectClick = (projectId) => {
    navigate(`/viewer/${projectId}`);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchSubmit = () => {
    setProjects([]); // Clear existing projects before new search
    setLastVisible(null); // Reset pagination
    fetchProjects(null);  // Fetch with current searchText
  };

  return (
    <Flex direction="column" align="center" m={4}>
      <Flex width="70%" mb={4} alignItems="center" minWidth="800px">
        <Input
          placeholder="プロジェクトを検索"
          value={searchText}
          onChange={handleSearchChange}
          flexGrow={1}
        />
        <Button leftIcon={<AiOutlineSearch />} onClick={handleSearchSubmit} ml={2}>
          検索
        </Button>
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3, xl: 4, '2xl': 5}} spacing={5} width="100%">
        {loading && projects.length === 0 && <Skeleton height="200px" width="100%" count={5} />}
        {(projects || []).map((project, index) => (
          <Box
            key={project.id}
            p={5}
            bg="white"
            borderRadius="md"
            onClick={() => handleProjectClick(project.id)}
            ref={index === projects.length - 1 ? observer : null}
          >
            <Box
              height="200px"
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundImage={`url(https://storage.googleapis.com/lasupload_assets/${project.id}/thumb.png)`}
              borderRadius="lg"
              cursor="pointer"
            />
           <Text fontSize="xl">{project.projectName}</Text>
            {showDisplayName && project.displayName && (
              <Box cursor="pointer" as={RouterLink} to={`/user/${project.customId}`} onClick={(e) => e.stopPropagation()}>
                <Icon as={AiOutlineUser} mr={2} />
                <Text as="span" mt={1} display="inline-block">{project.displayName}</Text>
              </Box>
            )}
            <Box>
              <Icon as={AiTwotoneCalendar} mr={2} />
              <Text as="span" mt={1} display="inline-block">{project.createdAt}</Text>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default ProjectList;
