import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Text, VStack } from '@chakra-ui/react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import ProjectList from './ProjectList'; // ProjectList コンポーネントをインポート
import { Helmet } from 'react-helmet'; // Helmet をインポート

function UserProfile() {
  const { customId } = useParams();
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('customId', '==', customId));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0].data();
        setUserData(userDoc);
        setUserId(userDoc.userId); // ユーザーIDを設定
      }
    };
    fetchUserData();
  }, [customId]);

  return (
    <VStack spacing={4} padding={4}>
      <Helmet>
        <title>{`${userData.displayName} | LASUPLOAD`}</title>
        <meta name="description" content={userData.profile} />
        <meta property="og:title" content={`${userData.displayName} | LASUPLOAD`} />
        <meta property="og:description" content={userData.profile} />
        <meta property="og:image" content="https://lasupload.com/thumbnail1200.png" />
        <meta property="og:url" content={`https://lasupload.com/user/${userData.customId}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Text fontSize="2xl" fontWeight="bold">{userData.displayName}</Text>
      <Text>{`@${userData.customId}`}</Text>
      <Text whiteSpace="pre-wrap">{userData.profile}</Text>
      {/* ProjectList コンポーネントを使用してプロジェクト一覧を表示 */}
      <Box width="100%">
        {/* ラインを追加 */}
        <Box borderBottom="1px" borderColor="gray.200" marginBottom={4} />
        {/*テキストをセンターに配置*/}
        <Text fontSize="xl" fontWeight="bold" textAlign="center">公開プロジェクト</Text>
        {userId && <ProjectList userId={userId} showPublicOnly={true} showDisplayName={false} />}
      </Box>
    </VStack>
  );
}

export default UserProfile;


