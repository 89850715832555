import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Text, Box, FormControl, FormLabel, Input, Textarea, Switch, Button, useToast, VStack, Spinner, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Heading } from '@chakra-ui/react';
import { useAuth } from '../context/AuthContext';
import { getFunctions, httpsCallable } from 'firebase/functions';

const ProjectSettings = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState(null);
  const { currentUser } = useAuth();

  const functions = getFunctions();

  const updateProject = httpsCallable(functions, 'updateProject');
  const deleteProject = httpsCallable(functions, 'deleteProject');

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  
  const formatDate = (timestamp) => {
    if (!timestamp) return '日付データがありません';
    
    // Firebase TimestampをDateオブジェクトに変換
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
  
    // 日付をフォーマット
    return date.toLocaleDateString('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  useEffect(() => {
    if (!currentUser) {
      toast({ title: '未認証のユーザー', description: 'ログインしてください。', status: 'error', duration: 5000, isClosable: true });
      navigate('/login');
      return;
    }

    setIsLoading(true);
    const docRef = doc(db, 'projects', projectId);
    getDoc(docRef).then(docSnap => {
      setIsLoading(false);
      if (docSnap.exists() && docSnap.data().userId === currentUser.uid) {
        const data = docSnap.data();
        setProject(data);
        setProjectName(data.projectName);
        setProjectDescription(data.projectDescription);
        setIsPublic(data.isPublic);
      } else {
        toast({ title: 'プロジェクトが見つかりません、またはアクセス権がありません', status: 'error', duration: 5000, isClosable: true });
        navigate('/');
      }
    });
  }, [currentUser, projectId, navigate, toast]);

  const handleUpdate = () => {
    setIsLoading(true);
    updateProject({ projectId, projectName, projectDescription, isPublic })
      .then(() => {
        setIsLoading(false);
        toast({ title: 'Project updated successfully', status: 'success', duration: 5000, isClosable: true });
      })
      .catch(error => {
        setIsLoading(false);
        toast({ title: 'Failed to update project', description: error.message, status: 'error', duration: 5000, isClosable: true });
      });
  };
  
  const handleDelete = () => {
    if (window.confirm("プロジェクトを削除してもよろしいですか？")) {
      setIsLoading(true);
      deleteProject({ projectId })
        .then(() => {
          setIsLoading(false);
          toast({ title: 'Project deleted successfully', status: 'success', duration: 5000, isClosable: true });
          navigate('/projects');
        })
        .catch(error => {
          setIsLoading(false);
          toast({ title: 'Failed to delete project', description: error.message, status: 'error', duration: 5000, isClosable: true });
        });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box p={5}>
      <Heading as="h2" size="lg" fontWeight="bold" textAlign="center">プロジェクト設定</Heading>
      {project && (
        <VStack spacing={4} align="stretch">
          <Text fontWeight='bold'>ファイル名</Text>
          <Text> {project.fileName}</Text>
          <Text fontWeight='bold'>ファイルサイズ</Text>
          <Text>{formatBytes(project.fileSize)}</Text>
          <Text fontWeight='bold'>アップロード日</Text>
          <Text>{formatDate(project.createdAt)}</Text>
        </VStack>
      )}
      <FormControl isRequired mt={4}>
        <FormLabel fontWeight='bold'>プロジェクト名</FormLabel>
        <Input value={projectName} onChange={(e) => setProjectName(e.target.value)} />
      </FormControl>
      <FormControl mt={4} isRequired>
        <FormLabel fontWeight='bold'>概要</FormLabel>
        <Textarea value={projectDescription} onChange={(e) => setProjectDescription(e.target.value)} maxLength={2500} />
      </FormControl>
      <FormControl mt={4} display="flex" alignItems="center">
        <FormLabel htmlFor="public-switch">公開</FormLabel>
        <Switch id="public-switch" isChecked={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />
      </FormControl>
      <Button size="sm" mr={2} mt={4} colorScheme="blue" isLoading={isLoading} onClick={handleUpdate}>更新</Button>
      <Button size="sm" mr={2} mt={4} colorScheme="red" isLoading={isLoading} onClick={handleDelete}>削除</Button>
    </Box>
  );
};

export default ProjectSettings;
