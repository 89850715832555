import React from 'react';
import { Box, Text, Link, Icon } from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';

function ProjectDetails({ projectData }) {
  return (
    <Box textAlign="left" width="70%" minWidth="600px" spacing={4} padding={4}>
      <Text fontSize="xl" fontWeight="bold">{projectData.title}</Text>
      <Box display="flex" alignItems="center">
        <Link to={`/user/${projectData.customId}`} onClick={(e) => e.stopPropagation()}>
          <Icon as={AiOutlineUser} mr={2} />
          <Text as="span" mt={2}>{projectData.userName}</Text>
        </Link>
      </Box>
      <Box backgroundColor="gray.100" padding={2} borderRadius="md" whiteSpace="pre-wrap">
        <Text fontSize="sm" mb={4}>{projectData.uploadDate}</Text>
        <Text fontSize="md">{projectData.description}</Text>
      </Box>
    </Box>
  );
}

export default ProjectDetails;
