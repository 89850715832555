import React, { useEffect, useState, useRef, useCallback } from 'react';
import { collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from '../context/AuthContext';
import { Box, Text, Badge, SimpleGrid, IconButton, Icon, Button, Image, Heading, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SettingsIcon, WarningIcon } from '@chakra-ui/icons';
import { AiTwotoneCalendar, AiOutlineFile } from 'react-icons/ai';


const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const UserProjects = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sortOption, setSortOption] = useState('newest');
  const [isPublicFilter, setIsPublicFilter] = useState(null);

  useEffect(() => {

    document.title = "プロジェクト一覧 | LASUPLOAD";
    if (!currentUser) return;
    setLoading(true);

    const constraints = [where('userId', '==', currentUser.uid), getOrderBy()];
    if (isPublicFilter !== null) {
      constraints.push(where('isPublic', '==', isPublicFilter));
    }

    const q = query(collection(db, 'projects'), ...constraints, limit(20));
    getDocs(q).then(querySnapshot => {
      const newProjects = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toLocaleString('ja-JP'),
        fileSize: formatBytes(doc.data().fileSize)
      }));
      setProjects(newProjects);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setLoading(false);
    });
  }, [currentUser, sortOption, isPublicFilter]);

  const getOrderBy = () => {
    switch (sortOption) {
      case 'newest': return orderBy('createdAt', 'desc');
      case 'oldest': return orderBy('createdAt', 'asc');
      case 'smallest': return orderBy('fileSize', 'asc');
      case 'largest': return orderBy('fileSize', 'desc');
      default: return orderBy('createdAt', 'desc');
    }
  };

  const handleProjectClick = projectId => {
    navigate(`/viewer/${projectId}`);
  };

  const handleSettingsClick = (projectId, event) => {
    event.stopPropagation();
    navigate(`/settings/${projectId}`);
  };

  return (
    <>
    <VStack spacing={4} align="stretch" m={5} p={5} >
    <Heading as="h2" size="lg" fontWeight="bold" textAlign="center">プロジェクト一覧</Heading>
      <Box display="flex" justifyContent="center">
        <Button size="sm" bg={isPublicFilter === null ? 'gray' : 'white'} mr={2} onClick={() => setIsPublicFilter(null)}>すべて</Button>
        <Button size="sm" bg={isPublicFilter === true ? 'gray' : 'white'} mr={2} onClick={() => setIsPublicFilter(true)}>公開</Button>
        <Button size="sm" bg={isPublicFilter === false ? 'gray' : 'white'} mr={2} onClick={() => setIsPublicFilter(false)}>非公開</Button>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button size="sm" bg={sortOption === 'newest' ? 'gray' : 'white'} mr={2} onClick={() => setSortOption('newest')}>新しい順</Button>
        <Button size="sm" bg={sortOption === 'oldest' ? 'gray' : 'white'} mr={2} onClick={() => setSortOption('oldest')}>古い順</Button>
        <Button size="sm" bg={sortOption === 'smallest' ? 'gray' : 'white'} mr={2} onClick={() => setSortOption('smallest')}>ファイルサイズの小さい順</Button>
        <Button size="sm" bg={sortOption === 'largest' ? 'gray' : 'white'} mr={2} onClick={() => setSortOption('largest')}>ファイルサイズの大きい順</Button>
      </Box>
      </VStack>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3, xl: 4 }} spacing={5}>
        {projects.map((project, index) => (
          <Box
            key={project.id}
            p={5}
            bg="white"
            cursor="pointer"
            borderRadius="md"
            position="relative"
            onClick={() => handleProjectClick(project.id)}
          >
            <IconButton
              icon={<SettingsIcon />}
              size="sm"
              position="absolute"
              top="5px"
              right="5px"
              onClick={(event) => handleSettingsClick(project.id, event)}
            />
            {project.status === 'completed' ? (
              <Box
                height="200px"
                backgroundSize="cover"
                backgroundPosition="center"
                backgroundImage={`url(https://storage.googleapis.com/lasupload_assets/${project.id}/thumb.png)`}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="lg"
              />
            ) : (
              <Box height="200px" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Icon as={WarningIcon} w={10} h={10} color="gray.500" />
                {['created', 'processing', 'failed'].includes(project.status) && (
                  <Badge m={5} colorScheme={
                    project.status === 'created' ? 'yellow' :
                      project.status === 'processing' ? 'orange' :
                        'red'
                  }>
                    {project.status === 'created' ? '処理待ち' :
                      project.status === 'processing' ? '処理中' :
                        '失敗'}
                  </Badge>
                )}
              </Box>
            )}
            {project.isPublic && <Badge colorScheme="green" position="absolute" top="5px" left="5px">公開</Badge>}
            <Text fontSize="xl">{project.projectName}</Text>
            <Text mt={2}>{project.description}</Text>
            <Text mt={2} fontSize="sm" color="gray"><Icon as={AiTwotoneCalendar} mr={2} />{project.createdAt}</Text>
            <Text mt={2}　fontSize="sm" color="gray"><Icon as={ AiOutlineFile} mr={2} />{project.fileSize}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </>
  );
};

export default UserProjects;
