import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Text, VStack, Button, Icon, Spinner } from '@chakra-ui/react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useAuth } from '../context/AuthContext';
import { AiOutlineUser } from 'react-icons/ai';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Helmet } from 'react-helmet';
import SplatViewer from './SplatViewer';
import ProjectDetails from './ProjectDetails';

function ViewerPage() {
  const { projectId } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [renderType, setRenderType] = useState(null); // 初期状態はnullとして、レンダリングタイプを保存
  const viewerContainerRef = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    async function fetchProject() {
      setIsLoading(true);
      const projectRef = doc(db, 'projects', projectId);
      const projectSnap = await getDoc(projectRef);
      if (projectSnap.exists()) {
        const project = projectSnap.data();
        const userRef = doc(db, 'users', project.userId);
        const userSnap = await getDoc(userRef);

        const userInfo = userSnap.exists() ? {
          userName: userSnap.data().displayName,
          customId: userSnap.data().customId,
        } : {
          userName: 'Unknown User',
          customId: '',
        };

        const newProjectData = {
          title: project.projectName,
          description: project.projectDescription,
          uploadDate: project.createdAt.toDate().toLocaleDateString('ja-JP'),
          ...userInfo,
          type: project.type,
          plyPath: project.plyPath
        };

        setProjectData(newProjectData);
        if (!renderType && project.type !== undefined) {
          setRenderType(project.type); // 最初にタイプが確定した時のみ状態をセット
        }
      } else {
        setProjectData(null);
      }
      setIsLoading(false);
    }

    fetchProject();
  }, [projectId]);
  
  if (isLoading || !projectData) {
    return <Spinner />;
  }

  // viewerUrl, handleFullscreen はロード後に使用可能なため、ここで定義
  const viewerUrl = `/potree/viewer.html?lazUrl=${encodeURIComponent(`https://storage.googleapis.com/lasupload_assets/${projectId}/metadata.json`)}`;

  const handleFullscreen = () => {
    if (iframeRef.current.requestFullscreen) {
      iframeRef.current.requestFullscreen();
    } else if (iframeRef.current.mozRequestFullScreen) {
      iframeRef.current.mozRequestFullScreen();
    } else if (iframeRef.current.webkitRequestFullscreen) {
      iframeRef.current.webkitRequestFullscreen();
    } else if (iframeRef.current.msRequestFullscreen) {
      iframeRef.current.msRequestFullscreen();
    }
  };

  return (
    // コンポーネントの戻り値
    <VStack>
      {/* Helmet を用いた SEO 対策 */}
      <Helmet>
        <title>{`${projectData.title} | LASUPLOAD`}</title>
        <meta name="description" content={projectData.description} />
        <meta property="og:title" content={`${projectData.title} | LASUPLOAD`} />
        <meta property="og:description" content={projectData.description} />
        <meta property="og:image" content="/thumbnail1200.png" />
        <meta property="og:url" content={`https://lasupload.com/viewer/${projectData.title}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* プロジェクトタイプに基づく条件分岐 */}
      {projectData.type === '3dgs' ? (
        <Box ref={viewerContainerRef} height="80vh" width="100%" maxHeight="1100px" position="relative">
          <SplatViewer plyPath={projectData.plyPath} containerRef={viewerContainerRef} />
        </Box>
      ) : projectData.type === 'pointcloud' ? (
        <Box height="80vh" width="100%" maxHeight="1100px" position="relative">
          <iframe src={viewerUrl} frameBorder="0" style={{ width: '100%', height: '100%' }} ref={iframeRef}></iframe>
          <Button onClick={handleFullscreen} position="absolute" bottom="0" right="0" bgColor="rgba(0, 0, 0, 0.6)" borderRadius="0">
            <Text fontSize="x-small" color="white">Full Screen</Text>
          </Button>
        </Box>
      ) : (
        <Text fontSize="xl">Project type is not supported or undefined.</Text>
      )}

      {/* プロジェクト情報の表示 */}
      <ProjectDetails projectData={projectData} />
    </VStack>
  );
}

export default ViewerPage;

