import React from 'react';
import { Box, VStack, ChakraProvider, Spinner } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import LoginForm from './components/LoginForm';
import SignupForm from './components/SignupForm';
import ResetPassword from './components/ResetPassword';
import PublicProjects from './components/PublicProjects'; // パブリックプロジェクト一覧ページ
import UserProjects from './components/UserProjects'; // プロジェクト一覧ページ
import UploadPage from './components/UploadPage'; // データアップロードページ
import AccountSettings from './components/AccountSettings'; // アカウント設定画面
import Header from './components/Header'; // ヘッダーをインポート
import Footer from './components/Footer'; // フッターをインポート
import ViewerPage from './components/ViewerPage'; // ビューワーを表示するコンポーネント
import ProjectSettings from './components/ProjectSettings'; // ビューワーを表示するコンポーネント
import UserProfile from './components/UserProfile';

function PrivateRoute({ children }) {
  const { currentUser, loading } = useAuth();

  // ローディング中はローディングアニメーションを表示
  if (loading) {
    return (
      <Box textAlign="center" paddingTop="20px">
        <Spinner size="xl" />
      </Box>
    );
  }

  // ローディングが終了してユーザーがいない場合、ログインページにリダイレクト
  return currentUser ? children : <Navigate to="/login" />;
}


function App() {
  return (
    <ChakraProvider>
      <Router>
        <AuthProvider>
          <VStack minHeight="100vh" justifyContent="space-between" spacing={0}>
            <Header /> {/* ヘッダーを追加 */}
            <Box flex="1" w="full">
                <Routes>
                  <Route path="/" element={<PublicProjects />} />
                  <Route path="/projects" element={<PrivateRoute><UserProjects /></PrivateRoute>} />
                  <Route path="/viewer/:projectId" element={<ViewerPage />} />
                  <Route path="/user/:customId" element={<UserProfile />} />
                  <Route path="/upload" element={<PrivateRoute><UploadPage /></PrivateRoute>} />
                  <Route path="/account" element={<PrivateRoute><AccountSettings /></PrivateRoute>} />
                  <Route path="/login" element={<LoginForm />} />
                  <Route path="/signup" element={<SignupForm />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/settings/:projectId" element={<ProjectSettings />} />
                </Routes>
            </Box>
          </VStack>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
