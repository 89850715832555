import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, VStack, Box, useToast, Text, FormControl, FormLabel, Switch, CircularProgress, Input, Icon, Textarea, Alert, AlertIcon, AlertDescription, AlertTitle, Link } from '@chakra-ui/react';
import { MdCloudUpload } from 'react-icons/md';
import { getStorage, ref as storageRef, uploadBytesResumable } from 'firebase/storage';
import { getFirestore, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const db = getFirestore();

function FileUploadForm() {

  useEffect(() => {
    document.title = "アップロード | LASUPLOAD";
  }, []);

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [isPublic, setIsPublic] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: useCallback(acceptedFiles => {
      const file = acceptedFiles[0];
      if (file && (file.name.endsWith('.las') || file.name.endsWith('.laz'))) {
        setFile(file);
        setProjectName(file.name);
        setProjectDescription(`${file.name} - ${Math.round(file.size / 1024 / 1024)} MB`);
      } else if (file && file.name.endsWith('.ply') ) {
        setFile(file);
        setProjectName(file.name);
        setProjectDescription(`${file.name} - ${Math.round(file.size / 1024 / 1024)} MB`);
      } else {
        toast({
          title: "無効なファイル形式",
          description: ".las/.laz/.ply ファイルのみ許可されています。",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }, [toast])
  });

  const handleUpload = async () => {
    if (!file) {
      toast({
        title: "ファイル未選択",
        description: "アップロードするファイルを選択してください。",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
  
    const projectId = uuidv4();
    const filePath = `uploads/${currentUser.uid}/${projectId}/${file.name}`;
    const storage = getStorage();
    const fileRef = storageRef(storage, filePath);
    const uploadTask = uploadBytesResumable(fileRef, file);
  
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        toast({
          title: "アップロード失敗",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
      async () => {
        // ファイルの拡張子に基づいてタイプを判断
        let fileType = '';
        if (file.name.endsWith('.las') || file.name.endsWith('.laz')) {
          fileType = 'pointcloud';
        } else if (file.name.endsWith('.ply')) {
          fileType = '3dgs';
        }
  
        await setDoc(doc(db, "projects", projectId), {
          userId: currentUser.uid,
          projectId,
          projectName,
          projectDescription,
          fileName: file.name,
          rawPath: filePath,
          isPublic,
          type: fileType, // ファイルタイプをFirestoreに保存
          status: 'created',
          createdAt: serverTimestamp(),
        });
  
        toast({
          title: "アップロード成功",
          description: "ファイルが正常にアップロードされ、情報が保存されました。",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setUploadProgress(0);
        navigate('/projects');
      }
    );
  };
  

  const togglePublic = () => setIsPublic(!isPublic);

  if (!currentUser.emailVerified) {
    return (
      <Alert status="warning" variant="subtle" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" height="200px">
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          メールアドレスの認証が必要です
        </AlertTitle>
        <AlertDescription maxWidth="x-lg">
          <Text>アップロードを始める前に、メールアドレスの認証を完了してください。</Text>
          <Text>
          再送信が必要な場合は
          <Link mt={2} color="teal.500" onClick={() => navigate('/account')}>アカウント設定ページ</Link>
          から行ってください。
          </Text>
        </AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <VStack p={6} w="full" {...getRootProps()} bg="gray.200" cursor="pointer">
        <input {...getInputProps()} />
        <Icon as={MdCloudUpload} w={10} h={10} color="gray.500" />
        <Text>ファイルをドロップまたはクリックして選択</Text>
      </VStack>
      {file && (
        <Box mt={5} w="full">
          <FormControl>
            <FormLabel>プロジェクト名:</FormLabel>
            <Input value={projectName}/>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>概要:</FormLabel>
            <Textarea value={projectDescription}/>
          </FormControl>
          <FormControl mt={4} display="flex" alignItems="center">
            <FormLabel htmlFor="public-switch" mb="0">
              公開・非公開
            </FormLabel>
            <Switch id="public-switch" onChange={togglePublic} isChecked={isPublic} />
          </FormControl>
          <Button colorScheme="blue" mt={4} onClick={handleUpload} isLoading={uploadProgress !== 0 && uploadProgress < 100}>
            アップロード
          </Button>
        </Box>
      )}
    </>
  );
}

export default FileUploadForm;


