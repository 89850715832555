import React, { useState, useEffect } from 'react';
import { Box, FormControl, FormLabel, Input, Button, useToast, VStack, Center, Heading } from '@chakra-ui/react';
import { getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from 'firebase/auth';

const SignupForm = () => {

  useEffect(() => {
    document.title = "新規登録 | LASUPLOAD";
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [customId, setCustomId] = useState('');
  const toast = useToast();
  const auth = getAuth();

  // Firestoreにユーザーデータを保存する関数
  const createUser = async (uid, customId, displayName) => {
    try {
      console.log('uid', uid);
      const response = await fetch('https://us-central1-kiraunix-b679e.cloudfunctions.net/createUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid, customId, displayName }),
      });
      if (!response.ok) {
        throw new Error('Failed to create user in database');
      }
      toast({
        title: 'User creation in database successful',
        description: 'User data has been successfully saved to the database.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to create user in database.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // サインアップ処理
  const handleSignup = async (event) => {
    event.preventDefault();

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;

      // Firebase Authにユーザープロファイルを更新
      await updateProfile(user, { displayName: username });

      // Firestoreにユーザードキュメントを作成
      await createUser(user.uid, customId, username);

       // ユーザーに認証メールを送信
       await sendEmailVerification(user);

      toast({
        title: 'アカウント作成成功',
        description: 'アカウントが正常に作成されました。',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      let errorMessage = 'アカウントの作成に失敗しました。';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'このメールアドレスは既に使用されています。';
      }
      toast({
        title: 'アカウント作成失敗',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Center minHeight="100vh">
      <Box p={8} maxWidth="500px" borderWidth={1} borderRadius={8} boxShadow="lg">
        <form onSubmit={handleSignup}>
          <VStack spacing={4}>
          <Heading as="h2" size="md" fontWeight="bold" textAlign="center">新規登録</Heading>
          <FormControl isRequired>
              <FormLabel>メールアドレス</FormLabel>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>ID</FormLabel>
              <Input type="text" value={customId} onChange={(e) => setCustomId(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>ユーザー名</FormLabel>
              <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>パスワード</FormLabel>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            <Button type="submit" colorScheme="blue" width="full">
              アカウント作成
            </Button>
          </VStack>
        </form>
      </Box>
    </Center>
  );
};

export default SignupForm;