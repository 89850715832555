import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import {
  Box, FormControl, FormLabel, Input, Button, Icon, useToast,
  Text, VStack, Heading, Progress, Textarea,
  Badge, Link,
  AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { getAuth, updateProfile, updateEmail, sendEmailVerification } from 'firebase/auth';
import { doc, getDoc, getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";

const AccountSettings = () => {

  const [isOpen, setIsOpen] = useState(false); // ダイアログ表示用のステート
  const onClose = () => setIsOpen(false); // ダイアログを閉じる関数
  const onDelete = async () => {
    setIsOpen(false); // ダイアログを閉じる
    try {
      const deleteUserAndDataFunc = httpsCallable(getFunctions(), 'deleteUserAndData');
      const result = await deleteUserAndDataFunc();
      toast({
        title: 'Account Deleted',
        description: 'Your account and all associated data have been successfully deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      // ここでユーザーをログアウトさせるなどの処理を行う
    } catch (error) {
      toast({
        title: 'Error Deleting Account',
        description: error.message || 'Failed to delete account.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  };

  const { currentUser } = useAuth();
  const auth = getAuth();
  const db = getFirestore();
  const toast = useToast();
  const updateFirestoreData = httpsCallable(getFunctions(), 'updateFirestoreData');
  const [isEditing, setIsEditing] = useState({
    email: false,
    username: false,
    profile: false,
    customId: false
  });
  const [formData, setFormData] = useState({
    email: currentUser.email,
    username: currentUser.displayName,
    registrationDate: new Date(currentUser.metadata.creationTime).toLocaleDateString(),
    profile: '',
    customId: '',
    storageUsage: '',
    plan: ''
  });
  const displayFields = [
    { key: 'email', label: 'メールアドレス' },
    { key: 'customId', label: 'ユーザーID' },
    { key: 'username', label: '表示名' },
    { key: 'profile', label: 'プロフィール概要' },
    { key: 'storageUsage', label: 'ストレージ利用量', type: 'progress' },
    { key: 'plan', label: 'プラン' },
    { key: 'registrationDate', label: 'アカウント作成日', type: 'date' }
  ];

  const [isUpdating, setIsUpdating] = useState(false); // 更新中の状態を追跡するためのステート

  useEffect(() => {
    document.title = "アカウント設定 | LASUPLOAD";
    const userRef = doc(db, "users", currentUser.uid);
    getDoc(userRef).then(docSnap => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setFormData(prev => ({
          ...prev,
          profile: data.profile || '',
          customId: data.customId || '',
          storageUsage: data.storageUsage || 0,
          plan: data.plan || 'Free'
        }));
        // プランに基づく maxFileSize を取得
        const planRef = doc(db, "plans", data.plan);
        getDoc(planRef).then(planSnap => {
          if (planSnap.exists()) {
            const planData = planSnap.data();
            setFormData(prev => ({
              ...prev,
              storageSize: planData.storageSize
            }));
          }
        });
      }
    });
  }, [currentUser, db]);

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const handleEditToggle = (field) => {
    setIsEditing({
      ...isEditing,
      [field]: !isEditing[field]
    });
  };

  const handleUpdate = async (field) => {
    setIsUpdating(true); // 更新処理開始時に非活性化を開始
    try {
      let response;
      if (field === 'email') {
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
          throw new Error('Invalid email format.');
        }
        await updateEmail(auth.currentUser, formData.email);
        await sendEmailVerification(auth.currentUser);
        toast({
          title: 'Email Updated Successfully',
          description: 'Verification email sent. Please check your email.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      } else if (field === 'username') {
        await updateProfile(auth.currentUser, { displayName: formData.username });
        // FirestoreでのDisplayNameの更新。fieldとvalueの形式に合わせて送信
        await updateFirestoreData({
          field: 'displayName', // Firestoreのフィールド名を指定
          value: formData.username // 更新する値
        });

        toast({
          title: 'Username Updated Successfully',
          description: 'Your display name has been updated.',
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      } else {
        // Custom ID and Profile are updated via Firestore function
        response = await updateFirestoreData({ uid: currentUser.uid, field, value: formData[field] });
        if (!response.data.success) {
          throw new Error(response.data.message || 'Data update failed.');
        }
        toast({
          title: `${field.charAt(0).toUpperCase() + field.slice(1)} Updated Successfully`,
          description: `${field.charAt(0).toUpperCase() + field.slice(1)} has been updated.`,
          status: 'success',
          duration: 5000,
          isClosable: true
        });
      }
      setIsEditing({
        ...isEditing,
        [field]: false
      });
    } catch (error) {
      console.error('Error while updating:', error);
      toast({
        title: 'Failed to Update',
        description: error.message || 'An unexpected error occurred.',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setIsUpdating(false); // 処理完了後にボタンを再度活性化
  };

  const resendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      toast({
        title: "Verification Email Sent",
        description: "Please check your email to verify your account.",
        status: "info",
        duration: 5000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: "Failed to Send Verification Email",
        description: error.message || "Failed to send verification email.",
        status: "error",
        duration: 5000,
        isClosable: true
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch" m={5} p={5} boxShadow="md" borderRadius="lg">
      <Heading as="h2" size="lg" fontWeight="bold" textAlign="center" mb={4}>アカウント情報</Heading>
      {displayFields.map(({ key, label, type }) => (
        <FormControl key={key} mt={4}>
          <FormLabel>{label}</FormLabel>
          {key === 'profile' ? (
            isEditing[key] ? (
              <>
                <Textarea
                  value={formData[key]}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                  placeholder="プロフィール詳細を入力"
                />
                <Button mt={2} size='sm' m={2} colorScheme="blue" onClick={() => handleUpdate(key)} disabled={isUpdating}>更新する</Button>
                <Button mt={2} size='sm' m={2} colorScheme="red" onClick={() => handleEditToggle(key)} disabled={isUpdating}>キャンセル</Button>
              </>
            ) : (
              <Text whiteSpace="pre-wrap">{formData[key]}</Text>
            )
          ) : key === 'email' ? (
            isEditing[key] ? (
            <>
              <Input
                type="text"
                value={formData[key]}
                onChange={(e) => handleInputChange(key, e.target.value)}
              />
              <Button mt={2} size='sm' m={2} colorScheme="blue" onClick={() => handleUpdate(key)} disabled={isUpdating}>更新する</Button>
              <Button mt={2} size='sm' m={2} colorScheme="red" onClick={() => handleEditToggle(key)} disabled={isUpdating}>キャンセル</Button>
            </>
            ) : (
              <>
              <Text whiteSpace="pre-wrap">{formData[key]}</Text>
              <Box>
              <Badge colorScheme={currentUser.emailVerified ? "green" : "red"}>
                {currentUser.emailVerified ? "認証済" : "未認証"}
              </Badge>
              {!currentUser.emailVerified && (
                <Link m={4} color="blue.500" onClick={resendVerificationEmail}>認証メールの再送信</Link>
              )}
              </Box>
              </>
            )
          ) : type === 'progress' ? (
            <>
              <Progress value={(formData.storageUsage / formData.storageSize) * 100} size="sm" colorScheme="green" />
              <Text mt={2}>{`${(formData.storageUsage / 1e6).toFixed(2)} MB / ${(formData.storageSize / 1e6).toFixed(2)} MB`}</Text>
            </>
          ) : isEditing[key] ? (
            <>
              <Input
                type="text"
                value={formData[key]}
                onChange={(e) => handleInputChange(key, e.target.value)}
              />
              <Button mt={2} size='sm' m={2} colorScheme="blue" onClick={() => handleUpdate(key)} disabled={isUpdating}>更新する</Button>
              <Button mt={2} size='sm' m={2} colorScheme="red" onClick={() => handleEditToggle(key)} disabled={isUpdating}>キャンセル</Button>
            </>
          ) : (
            <Text>{formData[key]}</Text>
          )}
          {(key !== 'storageUsage' && key !== 'registrationDate' && key !== 'plan' && !isEditing[key]) && <Icon as={EditIcon} ml={2} w={5} h={5} cursor="pointer" onClick={() => handleEditToggle(key)} />}
        </FormControl>
      ))}
    <Button colorScheme="red" onClick={() => setIsOpen(true)} width="200px">
        アカウントを削除する
      </Button>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={onClose} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              アカウントの削除
            </AlertDialogHeader>
            <AlertDialogBody>
              本当にアカウントを削除してもよろしいですか？この操作は元に戻すことができません。
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>
                キャンセル
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                削除
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default AccountSettings;
