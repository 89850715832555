import React, { useEffect, useRef, useState } from 'react';
import { WebGLRenderer } from 'three';
import { Viewer } from '@mkkellogg/gaussian-splats-3d';

const SplatViewer = ({ plyPath, containerRef }) => {
  const canvasRef = useRef(null);
  const viewerRef = useRef(null);
  const isMountedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const resizeRenderer = () => {
    const canvas = canvasRef.current;
    const container = containerRef.current;

    if (container && canvas && viewerRef.current) {
      const width = container.clientWidth;
      const height = container.clientHeight;
      viewerRef.current.renderer.setSize(width, height);
      viewerRef.current.camera.aspect = width / height;
      viewerRef.current.camera.updateProjectionMatrix();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeRenderer);
    return () => {
      window.removeEventListener('resize', resizeRenderer);
    };
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const loadScene = async () => {
      if (containerRef.current && canvasRef.current && !isLoading) {
        if (!viewerRef.current) {
          const renderer = new WebGLRenderer({ canvas: canvasRef.current, antialias: true });
          renderer.setPixelRatio(window.devicePixelRatio);
          renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);

          viewerRef.current = new Viewer({
            cameraUp: [0, -1, 0],
            initialCameraPosition: [-1.0, -1.5, -1.0],
            initialCameraLookAt: [0, 0, 0],
            renderer: renderer,
            sceneRevealMode: 1,
            sharedMemoryForWorkers: false,
          });
        }

        try {
          setIsLoading(true);
          await viewerRef.current.addSplatScene(`https://storage.googleapis.com/${plyPath}`, {
            splatAlphaRemovalThreshold: 5,
            showLoadingUI: true,
            position: [0, 0, 0],
            rotation: [0, 0, 0, 1],
            scale: [1.0, 1.0, 1.0]
          });
          if (isMountedRef.current) {
            viewerRef.current.start();
          }
        } catch (error) {
          console.error("Failed to add splat scene:", error);
        } finally {
          if (isMountedRef.current) {
            setIsLoading(false);
          }
        }
      }
    };

    loadScene();
  }, [plyPath, containerRef.current]);

  return <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />;
};

export default SplatViewer;



